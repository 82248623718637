@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';

html {
  height: 100%;
  margin: 0;
}
body {   
 background-image: linear-gradient(#e7cfb4, #da6d42);
 background-repeat: no-repeat;
 background-color: #da6d42;
}
